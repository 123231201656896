import { useExtractErrors } from '@/composables'
import { storeProduct, updateProduct } from '@api/product'
import { ref } from '@vue/composition-api'

export default function useProductView() {
  const loading = ref(false)

  const productErrors = ref([])

  const storeProductForm = async form => {
    loading.value = true
    productErrors.value = []

    return storeProduct({ ...form })
      .then(res => res)
      .catch(err => {
        productErrors.value = useExtractErrors(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateProductForm = async (pid, form) => {
    loading.value = true
    productErrors.value = []

    return updateProduct(pid, { ...form })
      .then(res => res)
      .catch(err => {
        productErrors.value = useExtractErrors(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,

    updateProductForm,
    storeProductForm,
    productErrors,
  }
}
