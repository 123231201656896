<template>
  <div class="product-create">
    <h1>Create Product Form</h1>
    <a @click="$router.back()">Back</a>
    <v-stepper
      v-model="activeStep"
      vertical
      class="mt-2"
    >
      <v-stepper-step
        step="0"
        :complete="completed(0)"
      >
        <div
          class="d-flex"
          :class="{ 'cursor-pointer': !productData }"
          @click="!productData ? (activeStep = 0) : null"
        >
          <span class="text--primary text-4xl font-weight-bold me-3">00</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">Choose Product Type</span>
            <span
              class="text--secondary text-xs"
            >{{ productType }}</span>
          </div>
        </div>
      </v-stepper-step>

      <v-stepper-content
        step="0"
      >
        <v-row>
          <v-col cols="2">
            <v-chip
              x-large
              :color="productType === 'tangible' ? 'primary' : ''"
              @click="chooseType('tangible')"
            >
              Tangible
            </v-chip>
          </v-col>
          <v-col cols="2">
            <v-chip
              x-large
              :color="productType === 'service' ? 'primary' : ''"
              @click="chooseType('service')"
            >
              Service
            </v-chip>
          </v-col>
          <v-col cols="2">
            <v-chip
              x-large
              :color="productType === 'voucher' ? 'primary' : ''"
              @click="chooseType('voucher')"
            >
              Voucher
            </v-chip>
          </v-col>
          <v-col cols="2">
            <v-chip
              x-large
              :color="productType === 'topup' ? 'primary' : ''"
              @click="chooseType('topup')"
            >
              Topup
            </v-chip>
          </v-col>
          <v-col cols="2">
            <v-chip
              x-large
              :color="productType === 'plan' ? 'primary' : ''"
              @click="chooseType('plan')"
            >
              Plan
            </v-chip>
          </v-col>
          <v-col cols="2">
            <v-chip
              x-large
              :color="productType === 'activity' ? 'primary' : ''"
              @click="chooseType('activity')"
            >
              Activity
            </v-chip>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step
        step="1"
        :complete="completed(1)"
      >
        <div
          class="d-flex cursor-pointer"
          @click="activateStep(1)"
        >
          <span class="text--primary text-4xl font-weight-bold me-3">01</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">Create Product</span>
            <span
              v-if="productData"
              class="text--secondary text-xs"
            >product: {{ productData.title.en }}</span>
          </div>
        </div>
      </v-stepper-step>

      <v-stepper-content step="1">
        <product-form
          v-if="formReady"
          :nature="productType"
          :cols="12"
          :mode="productData ? 'update' : 'create'"
          :resource="productData"
          :loading="loading"
          :errors="productErrors"
          @submit="handleProductForm"
        />
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { useNotifySuccess } from '@/composables'
import useProduct from '../useProduct'
import useProductForm from './useProductForm'

import ProductForm from './ProductForm.vue'

let prevProductType = ''

export default {
  components: {
    ProductForm,
  },

  setup() {
    const { loadProduct, productData } = useProduct()
    const {
      storeProductForm, updateProductForm, productErrors, loading,
    } = useProductForm()

    const activeStep = ref(0)
    const formReady = ref(true)
    const productType = ref(prevProductType)

    const lastStep = 6
    const completed = step => ({
      0: !!productType.value,
      1: !!productData.value,
    }[step])

    const nextStep = () => {
      for (let step = 0; step <= lastStep; step += 1) {
        if (!completed(step)) {
          activeStep.value = step

          return
        }
      }
      activeStep.value += 1
      activeStep.value = Math.min(activeStep.value, lastStep)
    }

    const activateStep = step => {
      if (!completed(step - 1)) return
      activeStep.value = step
    }

    const handleProductForm = async form => {
      const res = productData.value ? await updateProductForm(productData.value.id, form) : await storeProductForm(form)
      if (res) {
        loadProduct(res.data.data.id).then(() => {
          router.replace({ name: 'product-view', params: { id: productData.value.id } })
        })
        useNotifySuccess({ content: 'Product Create Succeeded.' })
      }
    }

    const chooseType = type => {
      productType.value = type
      prevProductType = type
      formReady.value = false
      setTimeout(() => (formReady.value = true))
      setTimeout(nextStep, 350)
    }

    const refresh = () =>

      // test
      loadProduct(productData.value.id).then(res => {
        // loadProduct(11).then(res => {
        const product = res.data.data
        store.dispatch('merchant/setCurrent', product.merchant)

        // nextStep() // no need
        formReady.value = false
        setTimeout(() => (formReady.value = true))
      })

    // productType.value = 'tangible' // test
    // refresh().then(() => setTimeout(nextStep, 1000)) // test
    nextStep()

    return {
      productType,
      chooseType,

      productData,
      productErrors,
      handleProductForm,

      loading,
      activeStep,
      activateStep,

      refresh,
      formReady,
      completed,
      nextStep,
    }
  },
}
</script>

<style lang="scss">
/** Min height applied to avoid flash when fresh form */
.height-250 {
  min-height: 250px;
}

/** Reserve space for error messages */
/* .product-create .v-stepper__step--active + .v-stepper__content .v-card { */
.product-create {
  .v-stepper__step--active {
    + {
      .v-stepper__content {
        .v-stepper__wrapper {
          padding-bottom: 50px;
          height: auto !important;
        }
      }
    }
  }
}
</style>
